
import { PropType, computed, defineComponent, getCurrentInstance, reactive } from 'vue';
import { DataFetchStatus, getExecuteStatusColor } from 'src/models/dataFetchStatus';
import { formatDate } from 'src/util/datetime';
import { isLoginUserMetricsAdmin } from 'src/models/new/User/loginUser';

type DataFetchStatusEx = DataFetchStatus & {
  startTimeDisp: string;
  endTimeDisp: string;
  metricsDetailDisp: string;
  executeTypeDisp: string;
};

interface State {
  isUserMetricsAdmin: boolean;
  listItems: DataFetchStatusEx[];
  isEmpty: boolean;
}

export default defineComponent({
  props: {
    dataFetchStatuses: {
      type: Array as PropType<DataFetchStatus[]>,
      required: true,
    },
  },
  setup(props) {
    const root = getCurrentInstance()!.proxy;

    const state: State = reactive({
      isUserMetricsAdmin: computed(() => isLoginUserMetricsAdmin(root.$store.getters['loginUser/data'])),
      listItems: computed(() => props.dataFetchStatuses.map(extendDataFetchStatus)),
      isEmpty: computed(() => state.listItems.length === 0),
    });

    const getMetricsDetailDispDate = (elem: DataFetchStatus): string => {
      if (!elem.metricsStartDt || !elem.metricsEndDt) {
        return '';
      }

      const start = formatDate(elem.metricsStartDt, 'yyyy/MM/dd');
      const end = formatDate(elem.metricsEndDt, 'yyyy/MM/dd');
      let ret;
      if (start === end) {
        // 同じ日付だったら範囲表示しない
        ret = `${start}: `;
      } else if (start.slice(0, 4) === end.slice(0, 4)) {
        // 同じ年だったら年は一回しか表示しない
        ret = `${start} 〜 ${end.slice(5)}: `;
      } else {
        ret = `${start} 〜 ${end}: `;
      }
      return ret;
    };

    const extendDataFetchStatus = (elem: DataFetchStatus): DataFetchStatusEx => {
      let metricsDetailDisp: string = getMetricsDetailDispDate(elem);
      if (elem.metricsNames) {
        metricsDetailDisp += elem.metricsNames;
      }
      metricsDetailDisp = metricsDetailDisp || '-';

      let executeTypeDisp = elem.executeTypeText;
      if (elem.logiscopeWorkplaceId) {
        executeTypeDisp += ` (集計用センター:${elem.logiscopeWorkplaceId})`;
      }

      return {
        ...elem,
        startTimeDisp: formatDate(elem.startTime, 'yyyy/MM/dd HH:mm:ss'),
        endTimeDisp: elem.endTime ? formatDate(elem.endTime, 'yyyy/MM/dd HH:mm:ss') : '',
        metricsDetailDisp,
        executeTypeDisp,
      };
    };

    return {
      props,
      state,
      getExecuteStatusColor,
    };
  },
});
