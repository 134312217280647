import { Company } from 'src/models/company';

// TODO: 全体的に作りが古いのでリファクタリング対象
// MasterCsvJobStatusと共通の型、およびメソッドを持つ

export const EXECUTE_TYPE_WEB_UPDATE = 'manual_get';
export const EXECUTE_TYPE_CSV_INPUT = 'csv_input';
export const EXECUTE_TYPE_DAILY_BATCH = 'daily_batch';
export const EXECUTE_TYPE_DAILY_BATCH_REMOTE_ORIGIN_METRICS = 'daily_batch_remote_origin_metrics';
export const EXECUTE_TYPE_AUTO_LOGIMETER_METRICS_UPDATE = 'auto_logimeter_metrics_update';
export const EXECUTE_TYPE_OPERATOR_BATCH = 'operator_batch';
export type EXECUTE_TYPE =
  | typeof EXECUTE_TYPE_WEB_UPDATE
  | typeof EXECUTE_TYPE_CSV_INPUT
  | typeof EXECUTE_TYPE_DAILY_BATCH
  | typeof EXECUTE_TYPE_DAILY_BATCH_REMOTE_ORIGIN_METRICS
  | typeof EXECUTE_TYPE_AUTO_LOGIMETER_METRICS_UPDATE
  | typeof EXECUTE_TYPE_OPERATOR_BATCH;

export const EXECUTE_STATUS_FAILED = 'failed';
export const EXECUTE_STATUS_IN_PROGRESS = 'in_progress';
export const EXECUTE_STATUS_COMPLETED = 'completed';
export type EXECUTE_STATUS =
  | typeof EXECUTE_STATUS_FAILED
  | typeof EXECUTE_STATUS_IN_PROGRESS
  | typeof EXECUTE_STATUS_COMPLETED;

export const EXECUTE_STATUS_COLOR_MAP = {
  [EXECUTE_STATUS_FAILED]: 'ed7d31',
  [EXECUTE_STATUS_IN_PROGRESS]: 'ffc000',
  [EXECUTE_STATUS_COMPLETED]: '70ad47',
};

export type DataFetchStatus = {
  id: number;
  companyId: number;
  logiscopeWorkplaceId: number | null;
  startTime: Date;
  endTime: Date | null;
  executeType: EXECUTE_TYPE;
  executeStatus: EXECUTE_STATUS;
  executeStatusText: string;
  executeTypeText: string;
  metricsStartDt: Date | null;
  metricsEndDt: Date | null;
  metricsNames: string | null;
  errorMessage: string | null;
};

export function getExecuteStatusColor(dataFetchStatus: Partial<DataFetchStatus>) {
  return EXECUTE_STATUS_COLOR_MAP[dataFetchStatus.executeStatus!];
}
